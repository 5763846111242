import React, { Fragment, useEffect, useState } from "react";
import { Empty, Badge, Affix, Button, Select, Spin } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Report } from "notiflix";
import moment from "moment";

import ImportantInfo from "../../atoms/important-updates";
import Navbar from "../../atoms/navbar";
import Footer from "../../atoms/footer";
import "./styles.css";

import { loginRequest } from "../../config/api-config";
import HeaderFarms from "../../utils/header_farms";
import API from "../../services";

function CapexHistory() {

    let navigate = useNavigate();
    const { Option } = Select;

    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(true)
    const isAuthenticated = useIsAuthenticated();
    const [historyCapex, setHistoryCapex] = useState([]);
    const [filter, setFilter] = useState('Waiting Approval')

    function handleChange(value) {
        setFilter(value)
    }

    function getAllHistory(accessToken) {
        API.HistoryCapex(HeaderFarms(accessToken)).then(res => {
            setHistoryCapex(res.data)
            setLoading(false)
        })
            .catch((err) => {
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });
    }

    function goDetail(id) {
        navigate(`/cr/view/${id}`);
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {
                getAllHistory(responseToken.accessToken)
            })
            .catch((e) => {
                handleLogin(instance)
            });
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then(() => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    useEffect(() => {
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <Fragment>
            <Navbar
                title="History"
                back={true}
                subtitle="Capex Request"
                navigate="/"
            ></Navbar>
            <div className="container white-back-cr" style={{ minHeight: 500 }}>
                <ImportantInfo></ImportantInfo>
                <div className="container mt-3 p-3">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <Affix offsetTop={10}>
                                <div className="col-md-6 d-flex justify-content-start">
                                    <Button type="primary" onClick={() => navigate("/capex/create")} size="middle">
                                        New Capex Request
                                    </Button>
                                </div>
                            </Affix>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">

                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <label style={{ marginRight: 10, marginTop: 5 }}>Filter</label>
                            <Select
                                defaultValue="Waiting"
                                style={{
                                    width: 120,
                                }}
                                onChange={handleChange}
                            >
                                <Option value="Waiting Approval">Waiting</Option>
                                <Option value="Approved">Confirmed</Option>
                                <Option value="Reject">Reject</Option>
                                <Option value="Cancel">Cancel</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {
                                historyCapex.length > 0 ?
                                    historyCapex.map(function (data, index) {
                                        if (filter === 'Waiting Approval') {
                                            if (data.approval.data.status === 'Waiting Approval') {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="#FF9900" text={"Waiting Approval"}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.record_id)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.status_request}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                        {moment(data.created_time).format('LLL')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Approved') {
                                            if (data.approval.data.status === 'Approved') {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="blue" text={"Approved"}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.record_id)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.status_request}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7" }}>
                                                                        {moment(data.created_time).format('LLL')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Reject') {
                                            if (data.approval.data.status === 'Reject') {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="red" text={data.approval.data.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.record_id)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.status_request}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7" }}>
                                                                        {moment(data.created_time).format('LLL')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }

                                        if (filter === 'Cancel') {
                                            if (data.approval.data.status === 'Cancel') {
                                                return (
                                                    <Fragment key={index}>
                                                        <Badge.Ribbon color="red" text={data.approval.data.status}>
                                                            <div
                                                                className="list-style p-3 px-4 mb-3"
                                                                onClick={() => goDetail(data.record_id)}
                                                            >
                                                                <div className="title mb-4">
                                                                    <p className="fs-7 mb-0">{data.status_request}</p>
                                                                </div>
                                                                <div className="hour d-flex justify-content-between m-1">
                                                                    <p className="fs-7" style={{ color: "#B7B7B7" }}>
                                                                        {moment(data.created_time).format('LLL')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Fragment>
                                                )
                                            }
                                        }
                                        return null;
                                    })
                                    :
                                    !loading && (
                                        <Empty className="mt-5" />
                                    )
                            }
                            {
                                historyCapex.length > 0 &&
                                (
                                    <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                        No more data
                                    </div>
                                )

                            }
                            {
                                loading && (
                                    <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                        <Spin size="large" />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    );
}

export default CapexHistory;
