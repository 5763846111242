import React from "react";

import Home from "../containers/home";

import CreateCapex from "../containers/capex/create";
import HistoryCapex from "../containers/capex/history"

import ApplicationLists from "../containers/application-list";
import CreateZoomRequest from "../containers/zoom-request";

import CreateTicket from "../containers/helpcenter/create-ticket";
import CreateTicketDetail from "../containers/helpcenter/create-ticket-detail";
import CreateTicketCheckin from "../containers/helpcenter/create-ticket-checkin";
import HistoryTicketDetail from "../containers/helpcenter/history-ticket-detail";
import HistoryTicket from "../containers/helpcenter/history-ticket";

import TSHistory from "../containers/timesheet/history";
import TSCreate from "../containers/timesheet/create";
import TSView from "../containers/timesheet/viewTs";
import ViewTSPrint from "../containers/timesheet/print";
import ViewTSPrintRange from "../containers/timesheet/print-range";

import WFPendingList from "../containers/workflow/pending";
import { Routes, Route } from "react-router-dom";

import VerificationChangeNumber from "../containers/verification/change";
import ViewBillingTelkomsel from "../containers/billing-telkomsel/view";
import ViewTsApprove from "../containers/timesheet/viewTsApprove";
import CompletedUserData from "../containers/completed-user";
import Approved from "../containers/workflow/approved";
import Verification from "../containers/verification";
import Login from "../containers/login";

import ViewSRApprove from "../containers/service-request/viewSRApprove";
import CreateServiceRequest from "../containers/service-request/create";
import SRHistory from "../containers/service-request/history";
import UnderConstruction from "../atoms/under-construction";
import ViewSR from "../containers/service-request/view";

import ViewTAApprove from "../containers/travel-authority/viewTAApprove";
import CreateTravelAuthority from "../containers/travel-authority/create";
import TAHistory from "../containers/travel-authority/history";
import ViewTAPrint from "../containers/travel-authority/print";
import ViewTA from "../containers/travel-authority/view";
import AssetSystem from "../containers/asset-system";
import CreateHias from "../containers/hias";

import ViewVWPApprove from "../containers/working-permit/viewVWPApprove";
import ViewWPApprove from "../containers/working-permit/viewWPApprove";
import CreateWorkingPermit from "../containers/working-permit/create";
import HistoryWP from "../containers/working-permit/history";
import ViewWPPrint from "../containers/working-permit/print";
import ViewWP from "../containers/working-permit/view";

import RequestDeliveryFuelAdjustment from "../containers/fuel-system/delivery-request-adjustment";
import ResetPasswordOperator from "../containers/fuel-system/reset-password-operator";
import ViewRequestDeliveryFuel from "../containers/fuel-system/view-delivery-request";
import FuelRequestAdjustment from "../containers/fuel-system/fuel-request-adjustment";
import CreateRequestDeliveryFuel from "../containers/fuel-system/delivery-request";
import CreateOperator from "../containers/fuel-system/operator-register";
import ViewRequestFuel from "../containers/fuel-system/view-request";
import CreateRequestFuel from "../containers/fuel-system/request-fuel";
import WorkPermitDashboard from "../containers/workpermit-dashboard";
import ProjectMonitoring from "../containers/project-monitoring";
import FuelMenu from "../containers/fuel-system/fuel-menu";
import FarmsActivity from "../containers/farms-activity";
import DriverMenu from "../containers/booking-driver";

import NotFound from "../atoms/404/404";
import Success from "../atoms/200";


function Router() {

  return (
    <Routes>

      <Route exact path="/" element={<Login />} />
      <Route exact path="/home" element={<Home />} />

      {/* Capex */}
      <Route exact path="capex/create" element={<CreateCapex />} />
      <Route exact path="capex/history" element={<HistoryCapex />} />
      <Route exact path="/cr/view/:id" element={<CreateCapex />} />
      <Route exact path="/wf/cr/view/:id" element={<CreateCapex />} />

      {/* Project Monitoring */}
      <Route exact path="project-monitoring" element={<ProjectMonitoring />} />

      {/* Working Permit Dashboard */}
      <Route exact path="wp-dashboard" element={<WorkPermitDashboard />} />

      {/* FARMS Activity Dashboard */}
      <Route exact path="farms-activity-dashboard" element={<FarmsActivity />} />

      {/* Zoom Request */}
      <Route exact path="zoom" element={<CreateZoomRequest />} />

      {/* Booking Driver */}
      <Route exact path="driver" element={<DriverMenu />} />
      <Route exact path="/wf/bd/view/:id" element={<DriverMenu />} />

      {/* Help center */}
      <Route exact path="help/history-ticket" element={<HistoryTicket />} />
      <Route exact path="help/history-ticket/detail/:id" element={<HistoryTicketDetail />} />
      <Route exact path="help/detail/:id" element={<HistoryTicketDetail />} />

      <Route exact path="help/create-ticket" element={<CreateTicket />} />
      <Route exact path="help/create-ticket-detail" element={<CreateTicketDetail />} />
      <Route exact path="help/create-ticket-checkin" element={<CreateTicketCheckin />} />

      {/* Timesheet */}
      <Route exact path="ts/create" element={<TSCreate />} />
      <Route exact path="ts/history" element={<TSHistory />} />
      <Route exact path="ts/view/:id" element={<TSView />} />
      <Route exact path="ts/print/:id" element={<ViewTSPrint />} />
      <Route exact path="ts/print-range" element={<ViewTSPrintRange />} />

      {/* Workflow Process */}
      <Route exact path="wf/pending" element={<WFPendingList />} />
      <Route exact path="wf/approved/:id" element={<Approved />} />

      {/* Approved Page */}
      <Route exact path="wf/ts/view/:id" element={<ViewTsApprove />} />
      <Route exact path="wf/bt/view/:id" element={<ViewBillingTelkomsel />} />
      <Route exact path="wf/sr/view/:id" element={<ViewSRApprove />} />
      <Route exact path="wf/ta/view/:id" element={<ViewTAApprove />} />
      <Route exact path="wf/wp/view/:id" element={<ViewWPApprove />} />
      <Route exact path="wf/vwp/view/:id" element={<ViewVWPApprove />} />
      <Route exact path="wf/frs/view/:id" element={<ViewRequestFuel />} />
      <Route exact path="wf/fds/view/:id" element={<ViewRequestDeliveryFuel />} />

      {/* Verification */}
      <Route exact path="/verification/:id/:token" element={<Verification />} />
      <Route exact path="/verification/change/:token" element={<VerificationChangeNumber />} />

      {/* Completed data User */}
      <Route exact path="/completed-user" element={<CompletedUserData />} />

      {/* Service Request */}
      <Route exact path="sr/create" element={<CreateServiceRequest />} />
      <Route exact path="sr/view/:id" element={<ViewSR />} />
      <Route exact path="sr/history" element={<SRHistory />} />

      {/* Travel Authority */}
      <Route exact path="ta/create" element={<CreateTravelAuthority />} />
      <Route exact path="ta/view/:id" element={<ViewTA />} />
      <Route exact path="ta/history" element={<TAHistory />} />
      <Route exact path="ta/print/:id" element={<ViewTAPrint />} />

      {/* HIAS */}
      <Route exact path="hias/create" element={<CreateHias />} />

      {/* Working Permit */}
      <Route exact path="wp/create" element={<CreateWorkingPermit />} />
      <Route exact path="wp/history" element={<HistoryWP />} />
      <Route exact path="wp/view/:id" element={<ViewWP />} />
      <Route exact path="wp/print/:id" element={<ViewWPPrint />} />

      {/* Fuel MS */}
      <Route exact path="fms/menu" element={<FuelMenu />} />
      <Route exact path="fms/request/create" element={<CreateRequestFuel />} />
      <Route exact path="fms/request/view/:id" element={<ViewRequestFuel />} />
      <Route exact path="fms/delivery/create" element={<CreateRequestDeliveryFuel />} />
      <Route exact path="fms/request/adjustment/:id" element={<FuelRequestAdjustment />} />
      <Route exact path="fms/delivery/adjustment/:id" element={<RequestDeliveryFuelAdjustment />} />
      <Route exact path="fms/delivery/view/:id" element={<ViewRequestDeliveryFuel />} />
      <Route exact path="fms/operator/register" element={<CreateOperator />} />
      <Route exact path="fms/operator/reset" element={<ResetPasswordOperator />} />

      {/* Under Constructions */}
      <Route exact path="under-construction" element={<UnderConstruction />} />

      {/* Application List */}
      <Route exact path="application-list" element={<ApplicationLists />} />

      {/* Asset App */}
      <Route exact path="asset-system" element={<AssetSystem />} />

      <Route path="200" element={<Success />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
